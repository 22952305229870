html {
    font-size: 16px;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-container {
    padding: 1rem !important;
    display: block !important;
    min-height: 100vh;
}

.profile-image, .my-profile-image {
    width: 100% !important;
    aspect-ratio: 1 !important;
    height: inherit !important;
}

.my-profile-image:hover {
    cursor: pointer;
    opacity: 0.7;
}

.connection:hover {
    cursor: pointer;
}

.connection-filter-button {
    position: fixed !important;
    top: 16px;
    right: 16px;
}

@media screen and (min-width: 0px) and (max-width: 576px) {
    html {
        font-size: 14px !important;
    }

    .app-container {
        min-height: calc(85vh - 2rem) !important;
    }

    .connection-filter-button {
        position: fixed;
        top: unset !important;
        right: unset !important;
        bottom: 16px !important;
        left: 16px !important;
    }
}